// import { createApp } from 'vue'
import Projects from "../vue/Projects.vue";
import Paginate from "vue-paginate";
import Vue from "vue";
import Swiper, { Navigation, Pagination, EffectFade, Controller, Autoplay } from 'swiper';
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import MicroModal from 'micromodal';
import SmoothScroll from "smooth-scroll";
import ScrollOut from "scroll-out";
import { ReadMore } from "./readmore";
// import { StickyBar } from "./sticky-bar";
import { AnchorNav } from "./anchor-nav";
import { StickyButtons } from "./sticky-buttons";
import { ScrollOffset } from "./scroll-offset";
import { LightBox } from "./fancybox";

// import { startCase } from "lodash";

Swiper.use([Navigation, Pagination, EffectFade, Controller, Autoplay]);

// Intiate Vuejs instance if we find #appVue (just in Projects page)
const appVue = document.getElementById( "appVue" );
if (appVue) {
    Vue.use(Paginate)

    var app = new Vue({
        el: '#appVue',
        delimiters: ["${","}"],
        components: {
            Projects
        }
    });
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {
    ReadMore.init();
    StickyButtons.init();
    AnchorNav.init();
    LightBox.init();
    ScrollOffset.init();

    MicroModal.init({
        onClose: modal => stopVideo(modal)
    });

    var stopVideo = function (modal) {
        // Look for  Vimeo video in the modal
        var liteVimeo = modal.querySelector('lite-vimeo');
        if (liteVimeo){
            const searchModuleRoot = liteVimeo.shadowRoot;
            var video = searchModuleRoot.querySelector('iframe');
            // Bail if the modal doesn't have a video
            if (!video) return;
            // If an HTML5 video, pause it
            if (video) {
                // Change autoplay to stop
                video.src = video.src.replace('?autoplay=1', '?autoplay=0');
            }
        }
    };

    ScrollOut({
        once: 'true',
    });

    var topHeader = document.querySelector(".section-header");
    let mobileMenu = document.querySelector( "#mobile-menu" );
    const projectWrapper = document.getElementById('app');

    if (mobileMenu){
        // Mobile menu init // Sync search and menu open
        const menu = new MmenuLight(mobileMenu,"(max-width: 1024px)" );
        const navigator = menu.navigation();
        const drawer = menu.offcanvas();

        // Open Menu
        const navTrigger = document.querySelector('.js-open-nav');

        // Toggle Menu
        bindEvent(navTrigger, "click", function() {
            this.classList.toggle('is-active');
            projectWrapper.classList.toggle('js-is-open');

            if (this.classList.contains('is-active')) {
                drawer.open();
            } else {
                drawer.close();
            }

        });

    }

    // BG Swiper //
    const swiperBG = new Swiper('.swiper-bg', {
        spaceBetween: 30,
        effect: 'fade',
        loop: false,
        speed: 500,
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },

    });

    //Content Swiper //
    const swiperContent = new Swiper('.swiper-content', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop: false,
        speed: 500,
        grabCursor: true,
        effect: 'fade',

        controller: {
            control: [swiperBG],
            by: 'container',
        },

        breakpoints: {
            768: {
                slidesPerView: 1,
            },
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

    });


    // check to see if the swiper exists //
    const swiperWrap = document.getElementById('swiperWrap');

    if(swiperWrap){
        swiperBG.controller.control = swiperContent;
          swiperContent.controller.control = swiperBG;
    }

    // circle swiper
    const swiperCircle = new Swiper(".circle-swiper", {
        slidesPerView: "auto",
        loop: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    const villasSwiper = new Swiper('.villas-swiper', {
        speed: 400,
        navigation: {
            nextEl: ".villas-swiper .swiper-button-next",
            prevEl: ".villas-swiper .swiper-button-prev",
        },
      });



        // testimnoial swiper
        const swiperTestimonial = new Swiper(".testimonial-swiper", {
            slidesPerView: "auto",
            loop: false,
            pagination: {
                el: ".testimonial-swiper .swiper-pagination",
                clickable: true,
            },
    
            navigation: {
                nextEl: ".testimonial-swiper .swiper-button-next",
                prevEl: ".testimonial-swiper .swiper-button-prev",
            },
        });
        

    // const swiperImgVideos = document.querySelectorAll('.video-img-swiper');

    // for( i=0; i< swiperImgVideos.length; i++ ) {
        
    //     swiperImgVideos[i].classList.add('video-img-swiper-' + i);

    //     var slider = new Swiper('.video-img-swiper-' + i, {

    //         slidesPerView: 1,
    //         effect: 'fade',
    //         watchSlidesProgress: true,
    //        // loop: true,

    //         // controller: {
    //         //     control: [swiperContentVideo],
    //         //     by: 'container',
    //         // },

    //         navigation: {
    //             nextEl: ".swiper-button-next",
    //             prevEl: ".swiper-button-prev",
    //         },

    //     });
    // }    
    // video img swiper
    // const swiperImgVideo = new Swiper(".video-img-swiper", {
    //     slidesPerView: 1,
    //     effect: 'fade',
    //     watchSlidesProgress: true,
    //    // loop: true,

    //     controller: {
    //         control: [swiperContentVideo],
    //         by: 'container',
    //     },

    //     navigation: {
    //         nextEl: ".swiper-button-next",
    //         prevEl: ".swiper-button-prev",
    //     },
    // });

    // // video content swiper
    // const swiperContentVideo = new Swiper(".video-content-swiper", {
    //     slidesPerView: 1,
    //    // loop: true,
    //     spaceBetween: 16,
    // });

    // // check to see if the swiper exists //
    // const swiperVideo = document.querySelectorAll('.section-video');

    // if(swiperVideo.length){
    //     swiperContentVideo.controller.control = swiperImgVideo;
    //     swiperImgVideo.controller.control = swiperContentVideo;
    // }


    // stick nav class
    apply_stickies()

    window.addEventListener('scroll', function() {
        apply_stickies()
    })

    function apply_stickies() {
        var _$stickies = [].slice.call(document.querySelectorAll('.section-sticky-nav'))
        _$stickies.forEach(function(_$sticky) {
            if (CSS.supports && CSS.supports('position', 'sticky')) {
                apply_sticky_class(_$sticky)
            }
        })
    }

    function apply_sticky_class(_$sticky) {
        var currentOffset = _$sticky.getBoundingClientRect().top
        var stickyOffset = parseInt(getComputedStyle(_$sticky).top.replace('px', ''))
        // var isStuck = currentOffset <= stickyOffset
        //This top line create some issue with the second level nav bar 
        //not stretching or being full width
        var isStuck = currentOffset <= (stickyOffset + 1)
        // console.log("currentOffset : " +currentOffset)
        // console.log("stickyOffset : " +stickyOffset)
        // console.log("isStuck : " +isStuck)

        _$sticky.classList.toggle('is-stuck', isStuck)
    }

    //Smoothscroll
    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,
        speedAsDuration: true, 
        header: '[data-scroll-header]',
        offset: '50',
    });

    const links = document.querySelectorAll('.section-sticky-nav__list .item a');
    const linkHashes = Array.from(links).map(x => x.hash)
    const sections = linkHashes.length ? document.querySelectorAll(linkHashes.join(', ')) : [];
    const scrollNav = document.querySelector('.section-sticky-nav');

    if(scrollNav){ 
        function changeLinkState() {
          let index = sections.length;

            while(--index && window.scrollY + 60 < sections[index].offsetTop) {}

           links.forEach(link => {
                link.classList.remove("active");
                link.classList.remove("hover");
            });
                     
          links[index].classList.add('active');
        }

        changeLinkState();
        window.addEventListener('scroll', changeLinkState);
    }

    // remove footer border
    const footer = document.querySelector('.footer');
    var element =  document.querySelector('.section-cta');
    if (typeof(element) != 'undefined' && element != null) {

        footer.classList.remove("border-t");

    }

    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }


    //Notification strip
    //Adding class to link inside the pop up text
    const popUpAnchors = document.querySelectorAll('.notification-strip') ;
    if(popUpAnchors.length){
        // Close message
        const close = document.querySelector('.close-notification');
    
        if(localStorage.getItem("popUpStatus") == null) {
            localStorage.setItem("popUpStatus", "Not Closed");
        }else if(localStorage.getItem("popUpStatus") == "Closed"){
            if(localStorage.getItem("popUpDate") != document.getElementById('notification-strip').getAttribute("dateupdate")){
                localStorage.setItem("popUpStatus", "Not Closed");
            }
        }
        bindEvent(close, "click", function() {
            localStorage.setItem("popUpStatus", "Closed");
            localStorage.setItem("popUpDate", document.getElementById('notification-strip').getAttribute("dateupdate"));
            message.classList.add('hidden');
            if (window.innerWidth < 1024) {
                var headerHeigth = 90;
            } else {
                var headerHeigth = 140;
            }
            updateHeaderHeight(headerHeigth);
            location.reload();
            return false;
        }, false);
    
        const message = document.querySelector('.js-message');
        // Setting session variable to set pop based on session
        if(localStorage.getItem("popUpStatus") == "Not Closed"){
            message.classList.remove('hidden');
        } else {
            message.classList.add('hidden');
        };
        function bindEvent(el, eventName, eventHandler) {
            if (el.addEventListener){
                el.addEventListener(eventName, eventHandler, false);
            } else if (el.attachEvent){
                el.attachEvent('on'+eventName, eventHandler);
            }
        }
    }

    function updateHeaderHeight(headerHeigth){
        var mainContent = document.getElementById('content');
        var headerHeigth = notificationStrip.offsetHeight + headerHeigth;
        topHeader.style.height = headerHeigth+'px';
        mainContent.style.paddingTop = headerHeigth+'px';
    }

    // Sticky navbar start
    const notificationStrip = document.getElementById('notification-strip');
    if (window.innerWidth < 1024) {
        var headerHeigth = 90;
    } else {
        var headerHeigth = 140;
    }
    if(notificationStrip){
        var mainContent = document.getElementById('content');
        var headerHeigth = notificationStrip.offsetHeight + headerHeigth;
        topHeader.style.height = headerHeigth+'px';
        mainContent.style.paddingTop = headerHeigth+'px';
    }
    var lastScroll = 0;
    var isScrolled = false;
    window.addEventListener("scroll", function () {
        var currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        var scrollDirection = currentScroll < lastScroll;
        var shouldToggle = isScrolled && scrollDirection;
        isScrolled = currentScroll > 1;
        // console.log("lastScroll: " +lastScroll);
        if(lastScroll <= headerHeigth ){
            topHeader.classList.add("active");
        }else{
            topHeader.classList.toggle("active", shouldToggle);
            topHeader.style.top = '-'+headerHeigth+'px';
        }
        if(topHeader.classList.contains('active')){
            topHeader.style.top = 0;
        }
        if(scrollNav && scrollNav.classList.contains('is-stuck') & topHeader.classList.contains('active')){
            scrollNav.style.top = headerHeigth+'px';
        }else if(scrollNav && scrollNav.classList.contains('is-stuck')){
            scrollNav.style.top = "0";
        }

        
        lastScroll = currentScroll;
    });
    // Sticky navbar ends

   // wysiwyg custom popup start
    const openPopup = document.querySelectorAll('.open-modal');
    if(openPopup.length){
        for (var i = 0; i < openPopup.length; i++) {
            openPopup[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                let parentWithClass = this.closest('.wysiwyg-modal');
                if(parentWithClass){
                    MicroModal.show('modal-popup' + parentWithClass.id);
                }
            });
        }
    }
    // wysiwyg custom popup  ends

    const openSubscribePopup = document.querySelectorAll(".open-subscribe-modal");
    console.log("here");
    if (openSubscribePopup.length) {
        console.log('boomfa');
        for (var i = 0; i < openSubscribePopup.length; i++) {
            openSubscribePopup[i].addEventListener("click", function (e) {
                e.stopPropagation();
                MicroModal.show(
                    "modal-popup-subscribe"
                );
            });
        }
    }


})
