export const ScrollOffset = {
    init: () => {
        const topHeader = document.querySelector(".section-header");
        const anchorNav = document.querySelector(".anchor-nav");
        // const stickyBar = document.querySelector(".sticky-bar");

        if (!anchorNav) {
            return; // Exit if no anchor nav found
        }

        const anchorLinks = anchorNav.querySelectorAll(".anchor-nav__link");

        anchorLinks.forEach((link) => {
            link.addEventListener("click", function (event) {
                event.preventDefault();
                const anchorId = this.getAttribute("href");
                AnchorScrollTo(anchorId);

                // remove current class from other links
                anchorLinks.forEach((link) => {
                    if (link !== this) {
                        link.classList.remove("current");
                    } else {
                        link.classList.add("current");
                    }
                });
            });
        });

        function AnchorScrollTo(anchorId) {
            const target = document.querySelector(anchorId);
            if (target) {
                const stickyBarHeight =
                    stickyBar && !stickyBar.classList.contains("hide")
                        ? stickyBar.clientHeight
                        : 0;

                const targetPosition = target.offsetTop;
                const offsetPosition =
                    targetPosition - anchorNav.clientHeight;

                const scrollOptions = {
                    top:
                        window.scrollY > targetPosition
                            ? offsetPosition - topHeader.clientHeight
                            : offsetPosition,
                    behavior: "smooth",
                };

                window.scrollTo(scrollOptions);
            }
        }
    },
};
