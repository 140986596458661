<template>
  <main>
    <div class="w-full">
      <label class="block">
        <p class=" ">Filter&nbsp;by</p>
        <!--        Filter for the categories (shows all)           -->
        <select class="form-select block" v-on:change="updateSelectedFilter($event, 'categoryType')">
          <option v-bind:value="'all'">Project type</option>
          <option v-for="category in categories" :selected="category.selected"
                  v-bind:value="category.id">
            {{ category.title }}
          </option>
        </select>
        <!--        Filter for the expertise areas (shows all)       -->
        <select class="form-select block" v-on:change="updateSelectedFilter($event, 'expertise')">
          <option v-bind:value="'all'">Expertise</option>
          <option v-for="expertiseItem in expertise" :selected="expertiseItem.selected"
                  v-bind:value="expertiseItem.id">
            {{ expertiseItem.title }}
          </option>
        </select>
        <!--        Filter for the available regions (just shows the ones available) -->
        <select class="form-select block" v-on:change="updateSelectedFilter($event, 'region')">
          <option v-bind:value="'all'">Region</option>
          <option v-for="(region, index) in regions" :selected="region.selected"
                  v-bind:value="region" v-if="index % 2 === 0">
            {{ getRegionLabel(index) }}
          </option>
        </select>
      </label>
    </div>

    <div class="">
      <paginate name="items" ref="paginator" :list="items" :per="3"
                class="paginate-list py-6 md:flex md:justify-start md:flex-wrap">
        <li class="w-full md:w-1/2 lg:w-1/3" v-for="project in paginated('items')">
          <div class="mb-6">
            <img class="w-full" v-bind:src="project.image"
                 v-bind:alt="project.name">
          </div>

          <div class="flex items-center justify-start"><h2 class="h2 mb-3">{{ project.name }}</h2></div>
          <div v-if="project.description" v-html="project.description"></div>
          <a class="" v-if="project.url" v-bind:href="project.url">
            View project
          </a>
        </li>
      </paginate>
    </div>

    <div class="">
      <paginate-links for="items" id="pagi" :limit="3" :show-step-links="true" :step-links="{
                next: 'Next', prev: 'Prev'}">
      </paginate-links>
    </div>
  </main>
</template>

<script>

export default {

  props: [
    'projects',
    'categories',
    'regions',
    'expertise'
  ],

  data() {
    return {
      selectedCategory: '',
      selectedExpertise: '',
      selectedRegion: '',
      projectsArray: this.projects,
      items: this.projects,
      locations: this.regions,
      paginate: ['items'],
      expertiseCurrentFilter: 'all',
      categoryTypeCurrentFilter: 'all',
      regionCurrentFilter: 'all',
    }
  },

  methods: {
    /**
     * Get the label of the location (region)
     *
     */
    getRegionLabel: function (index) {
      return this.locations[+index + 1];
    },

    /**
     * Update the list of projects based on the filters
     *
     * @param $event
     * @param filterType : could be 'expertise', 'region' or 'categoryType'
     */
    updateSelectedFilter: function (e, filterType) {
      e.preventDefault();
      var filter = e.target.selectedOptions[0].value;

      // Update the global filters
      switch (filterType) {
        case 'expertise':
          this.expertiseCurrentFilter = filter;
          break;
        case 'region':
          this.regionCurrentFilter = filter;
          break;
        case 'categoryType':
          this.categoryTypeCurrentFilter = filter;
          break;
      }

      if (this.regionCurrentFilter !== "all" || this.categoryTypeCurrentFilter !== "all" || this.expertiseCurrentFilter !== "all") {
        this.items = this.getByAllFilters(this.projectsArray); // filter projects
      } else {
        this.items = this.projectsArray // no filter
      }

      if (this.items.length) {
        document.querySelector("#pagi > li.left-arrow > a").click() // patch bug from plugin
      }
    },

    /**
     * Filter the project list by all the filters (Category + Region + Expertise)
     *
     * @param projects
     * @returns {*} updated list of projects with the 3 filters
     */
    getByAllFilters: function (projects) {
      return this.getByCategory(
          this.getByRegion(
              this.getByExpertise(
                  projects, this.expertiseCurrentFilter),
              this.regionCurrentFilter),
          this.categoryTypeCurrentFilter);
    },

    /**
     * Check which projects belong to the given category
     * One project belongs to one and only one category
     *
     * @param list
     * @param category
     * @returns {*} updated list of projects with the category filter
     */
    getByCategory: function (list, category) {
      if (!category || category === 'all') return list
      return list.filter(item => item.category === +category)
    },

    /**
     * Check which projects belong to the given region
     * One project belongs to one and only one region
     *
     * @param list
     * @param region
     * @returns {*} updated list of projects with the region filter
     */
    getByRegion: function (list, region) {
      if (!region || region === 'all') return list
      return list.filter(item => item.region === region)
    },

    /**
     * Check which projects belong to the given expertise
     * One project belongs to one or multiple expertise areas
     *
     * @param list
     * @param expertise
     * @returns {*} updated list of projects with the expertise filter
     */
    getByExpertise: function (list, expertise) {
      if (!expertise || expertise === 'all') return list
      return list.filter(item => item.expertise.includes(expertise))
    }

  },

  computed: {
    //
  },

}
</script>