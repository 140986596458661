export const ReadMore = {
    init: () => {
        const elements = document.querySelectorAll(".read-more");
        if (!elements.length) {
            // console.error("No content elements found. Please make sure you have the correct class name for content elements.");
            return;
        }

        elements.forEach(content => {
            const readmoreButton = content.querySelector(".read-more__button");
            const readmoreContent = content.querySelector(
                ".read-more__content"
            );
            const contentOverlap = content.querySelector(".content-overlap");

            if (!readmoreButton) {
                console.error(
                    "Read more button not found within content element. Please make sure you have the correct class name for the read more button."
                );
                return;
            }

            if (readmoreContent.scrollHeight > 600) {
                
                readmoreButton.style.display = "flex";

                readmoreButton.addEventListener("click", () => {
                    content.classList.toggle("expanded");
                    // toggle the content overlap and roate the arrow
                    contentOverlap.classList.toggle("hidden");
                    readmoreButton.querySelector("svg").classList.toggle("rotate-180");

                    if (content.classList.contains("expanded")) {
                        readmoreButton.querySelector("span").textContent =
                            "Read less";
                        // readmoreButton.querySelector("svg").style.transform =
                        //     "rotate(180deg)";
                    } else {
                        readmoreButton.querySelector("span").textContent =
                            "Read more";
                        // readmoreButton.querySelector("svg").style.transform =
                        //     "none";
                    }
                });
            } else {
                contentOverlap.classList.add("hidden");
            }
        });
    }
};
