export const StickyButtons = {
    init: () => {
        const stickyButtons = document.querySelector(
            ".unit-profile__sticky-buttons",
        );

        if (stickyButtons) {
            const stickyButtonOffset =
                stickyButtons.getBoundingClientRect().bottom +
                window.pageYOffset;

            function toggleStickButtonsyDisplay() {
                if (window.scrollY >= stickyButtonOffset) {
                    stickyButtons.classList.add("is-stuck");
                } else {
                    stickyButtons.classList.remove("is-stuck");
                }
            }
            window.addEventListener("scroll", toggleStickButtonsyDisplay);
        }
    },
};
