import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export const LightBox = {
    init: () => {
        Fancybox.bind('[data-fancybox="gallery"]', {
            // Carousel:
        });
    },
};
