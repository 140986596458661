export const AnchorNav = {
    init: () => {
        const anchorNav = document.querySelector(".anchor-nav");
        const topHeader = document.querySelector(".section-header");

        if (anchorNav && topHeader) {
            function updateStickyPosition() {
                if (topHeader.classList.contains("active")) {
                    anchorNav.style.top = topHeader.clientHeight + "px";
                } else {
                    anchorNav.style.top = "-1px";
                }
            }

            // Add event listeners for scroll and resize events
            function handleScrollEvents() {
                updateStickyPosition();
            }

            // Attach combined scroll event listener
            window.addEventListener("scroll", handleScrollEvents);
        }
    },
};
